import Container from "../components/Container";
import Profile from "../components/Profile";
import Link from "../components/Link";


import { IoBusinessOutline } from 'react-icons/io5'
import { MdOutlineBusinessCenter } from 'react-icons/md'


import dm2Pic from '../content/dm2_pic.png'
import useLanguage from "../hooks/use-language";
import { IconContext } from "react-icons";



function About() {

    const { translate } = useLanguage()

    return (
        <>
            <IconContext.Provider value={{ color: "white", className: "justify-self-center", size: '40vh', }}>
                <Container className='bg-sky-700'>
                    <div className='flex flex-col lg:flex-row p-4 text-white items-center'>
                        <div className='flex flex-col items-center'>
                            <h1 className='mb-5'>{translate('company')}</h1>
                            <p className='whitespace-pre-line lg:pl-20 lg:pr-20' width='50%'>{translate('companyContent')}</p>
                        </div>
                        <IoBusinessOutline className="" />
                    </div>
                </Container>
                <Container className='bg-sky-500'>
                    <div className='flex flex-col lg:flex-row p-4 text-white items-center'>
                        <MdOutlineBusinessCenter  className="m-1 md-18" />

                        <div className='flex flex-col items-center'>
                            <h1 className='mb-5'>{translate('business')}</h1>
                            <p className='whitespace-pre-line lg:pl-20 lg:pr-20' width='50%'>{translate('businessContent')}</p>
                        </div>
                    </div>
                </Container>
            </IconContext.Provider>

            <Container className='bg-sky-300 min-h-[5vh]'>
                <div className='flex flex-col p-4 text-white'>
                    <div className='flex flex-col items-center'>
                        <h1 className='mb-3'>{translate('team')}</h1>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-around'>
                        <Profile
                            imgSrc={dm2Pic}
                            title={translate('president')}
                            name='Douglas'
                        />
                        <Profile
                            imgSrc={dm2Pic}
                            title={translate('opFin')}
                            name='Claudia'
                        />
                        <Profile
                            imgSrc={dm2Pic}
                            title={translate('insiderSale')}
                            name='Stefanie'
                        />
                        <Profile
                            imgSrc={dm2Pic}
                            title={translate('sales')}
                            name='Kyle'
                        />
                        <Profile
                            imgSrc={dm2Pic}
                            title={translate('sales')}
                            name='Rachael'
                        />

                    </div>

                </div>
            </Container>
            <div className="min-h-[20vh] max-h-[40vh] flex flex-col md:flex-row content-center items-center justify-center bg-sky-100 content-center align-center">
                <h1 className="mr-10">{translate('questions')}</h1>
                <Link
                    to='/contact'
                    className='
                animate-pulse 
                transition 
                ease-in-out 
                delay-50 
                bg-blue-500 
                hover:-translate-y-1 
                hover:scale-110 
                hover:bg-indigo-500 
                duration-300 
                max-w-[150px] 
                max-h-[50px] 
                rounded-md 
                p-2 
                text-white'>
                    {translate('contactUs')}
                </Link>
            </div >


        </>

    );
}
export default About;