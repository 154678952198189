import classNames from "classnames";

function Profile({ className, imgSrc, title, name }) {
    const profileClasses = classNames(
        'flex flex-col items-center m-2 justify-self-stretch',
        className

    );

    return (
        <div className={profileClasses}>
            <h4 className="font-bold">{name}</h4>
            <h5>{title}</h5>
        </div>
    );

}
export default Profile;